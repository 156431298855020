import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Footer(){
	return(
		<Container fluid className='theme vw-100 mx-0'>
			<Container className='p-4 navFont'>
				<Row>
					<p className="navFont">Location</p> {/*change font*/}
				</Row>
				<Row>
					<Col>
						<p>1009 Guerrero St
				   		<br />
			   	   		San Francisco, 
			   	   		<br />
			   	   		CA 94110
			   	   		<br /><br />
			   	   		(415) 923-8600
			   	   		</p>
			   	   	</Col>
			   	   	<Col>
			   	   		<p>
			   	   		Sunday - Thursday
			   	   		<br />
						5pm - 9:30pm
						<br /><br />
						Friday - Saturaday
						<br />
						5pm - 10pm
						<br />
			   	   		</p>
			   	   	</Col>
				</Row>
			</Container>
		</Container>
	)
}

export default Footer;