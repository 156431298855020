import Navbar from 'react-bootstrap/Navbar'
import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import {CgMenuRightAlt} from 'react-icons/cg'
function Navigation() {
	return(
		<Container className='vw-100'>
		<Navbar className="px-3 theme w-100" expand="md lg" fixed="top">
	    <Navbar.Brand href="/" className='fenikkusu'><img src="/logo.png" alt='logo' style={{height:"64px", width:"64px"}}/>F e n i k k u s u</Navbar.Brand> 		
	    <Navbar.Toggle aria-controls="basic-navbar-nav" className='nav-toggle'> 
	    	<CgMenuRightAlt/>
	    </Navbar.Toggle>
	    <Navbar.Collapse id="basic-navbar-nav">
			<Container>
		      <Nav className="justify-content-end navFont">
		        <Nav.Link href="/home" >Home</Nav.Link>
		        <Nav.Link href="/about">About Us</Nav.Link>
		        <Nav.Link href="/menu">Menu</Nav.Link>
		      </Nav>
			</Container>
	    </Navbar.Collapse>
		</Navbar>
		</Container>
	)
}

export default Navigation;