import './App.css';
import Navigation from "./component/Navigation";
import Footer from "./component/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Menu from "./pages/Menu";
import React from "react";
import { BrowserRouter, Switch, Route} from "react-router-dom";



function App() {
  return (
    <BrowserRouter>
     <Navigation/>
     <Switch>
       <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/menu">
          <Menu />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
     </Switch>
     <Footer />
    </BrowserRouter>
  );
}

export default App;
