import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styling.css'

function Menu(){
	return(
		<Container className="componentBody">
			<Row className='justify-content-center'>
				<img src="/fenikkusu_menu_05_2022.png" alt='tapas_menu' className="images-full-width"/>
			</Row>
		</Container>
		)
}
export default Menu;