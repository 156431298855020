import React from 'react'
import Container from 'react-bootstrap/Container'
import '../styling.css'

function Contact() {
	return(
		<Container className="componentBody">
			<h1>Contact</h1>
		</Container>
		)
}
export default Contact;