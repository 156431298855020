import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import '../styling.css'

class Home extends React.Component{
	constructor(props) {
    	super(props);
        this.videoFrame = React.createRef();
  	}

  	onClick = ()=> {
  		console.log('hello')
  	}
	render(){
		return(
		<Container fluid className="componentBody"> {/*body*/}
			<Row className="vw-100 mb-5 mx-0 position-relative align-items-stretch justify-content-center justify-content-lg-end videoframe"> 
					<iframe src="https://www.youtube.com/embed/3q5dA1wYc-g?controls=1&autoplay=1&mute=0&loop=1&playlist=3q5dA1wYc-g&modestbranding=1" title='Fenikkusu SF'/>
			</Row>
			<Row className="mb-5 mx-0 justify-content-center align-items-center" sm={1} md={2} lg={2}> 
				<Col>
					<Image src='george(fish).jpg' alt='George Z' className="images"/>
				</Col>
				<Col>
				<div className='home2'>
					<article className='py-5 px-3 m-0'>
						<h2>Welcome to Fenikkusu</h2>
						<p className='px-4'> 
							<br />
							Early 2020, Chef George founded Fenikkusu (or "Pheonix" in English) 
							in San Francisco, California, to provide a casual spot for locals 
							and visitors to relax and enjoy the culinary experience.
							<br /><br />
							The owner George Z. started his sushi career at the 
							year of 1998 in the legendary Kamakura@Alameda.
							<br /><br />
							Since then, he perfects his skill meticulously. 
							He believes in "Respect, Discipline, and Detail" and 
							incorporates these principles in his cooking. Here are 
							some of his ORIGINAL creations over the past 20 years:
							<br /><br />
							Hon Maguro Quattro<br />
							Iku-Uni-Himo (Ikura, Sea Urchin, and Shaved Ankimo)<br />
							Ika & Uni<br />
							Saffron Mussels w/ Miso<br />
							Ahi Tuna Bruschetta<br />
							Butterfly-cut Hamachi Toro Nigiri<br />
							Blue shrimp w/ Pinenuts <br />
							Baked Baby Scallops, etc.<br />
							<br />
							These dishes have been an inspiration to many other sushi restaurants in the Bay Area.
							<br /><br />
							</p>
						</article>
					</div>
				</Col>
			</Row>
			<Row className="mb-5 mx-0 position-relative justify-content-center justify-content-lg-end minivideoframe"> 
					<iframe src="https://www.youtube.com/embed/3q5dA1wYc-g?controls=1" title='Fenikkusu SF' style={{'height': '360px'}}/>
			</Row>
			<Row className="mb-5 mx-0"> {/*body3*/}
				<Image src="ingredient.png" style={{'height':'90%','objectFit': 'contain', 'padding': '0'}}/> 
			</Row>

			<Row className="mb-5 py-3 mx-0">
				<h3 style={{'textAlign':'center'}}>Fresh ingredients. Daily.</h3>
			</Row>

			<Row className="px-3 mb-3 mx-0">
				<h2 className='navFont'>Gallery</h2>
			</Row>
			<Row className="m-2 m-md-5 justify-content-center align-items-center gallery" lg={3}>	
				<Col className='p-1' xs={6} md={4} ><Image className='images-full-width-height' src='food/mussel.png'/></Col>	
				<Col className='p-1' xs={6} md={4} ><Image className='images-full-width-height' src='tapas1_square.png'/></Col>	
				<Col className='p-1' xs={6} md={4} ><Image className='images-full-width-height' src='food/hamachitoro.png'/></Col>

				<Col className='p-1' xs={6} md={4} ><Image className='images-full-width-height' src='food/platter.jpg'/></Col>
				<Col className='p-1' xs={6} md={4} ><Image className='images-full-width-height' src='food/ikauni.png'/></Col>
				<Col className='p-1' xs={6} md={4} ><Image className='images-full-width-height' src='food/ikuunihimo.jpg'/></Col>
			</Row>
		</Container>
		) 
	}
}
export default Home;