import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import '../styling.css'

function About(){
	return(
		<Container className="componentBody mx-0">
			<Row className="vh-100 vw-100 m-0 justify-content-center align-items-center justify-content-lg-start">
						<article className="position-absolute hometitle m-lg-5 px-5 py-3">
						<h1 className="my-3">About Us</h1>
						<p>(415) 923-8600</p>
						<p>fenikkususf@gmail.com</p>
						<p>1009 Guerrero St<br/>
					   		San Francisco</p>
					   		<a href="https://www.doordash.com/store/968304/?utm_source=partner-link&utm_medium=website&utm_campaign=968304&utm_content=white-small" target="_blank"  rel="noreferrer" alt="Order Food Delivery with DoorDash" title="Order Food Delivery with DoorDash" style={{"textDecoration": "none"}}>
					   		<div className="doordash"></div></a>
						</article>
					<Image src="/indoor.png" className="about-images"/>

			</Row>
		</Container>
	)
}
export default About;